import { useCookieConsent } from '@packages/shared/src/hooks/useCookieConsent/useCookieConsent';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useEffect } from 'react';

export const Squarelovin = ({ streamType }: { streamType: 'gallery' | 'teaser' }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  const { locale } = useI18n();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'renderSquarelovinScript',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGTMEvent, isAllowed]);

  return (
    <div key="squarelovin-script">
      <div
        className="squarelovin-container"
        data-sq-template={streamType}
        data-sq-lang={locale.toLowerCase().replace('-', '_')} // fr-CH -> fr_ch
        data-sq-import-on-load="outline"
      />
    </div>
  );
};
